import React from "react";
import { Button, Modal, Backdrop } from "@mui/material";
import { useSpring, animated } from "react-spring"; // web.cjs is required for IE 11 support

import CloseIcon from "../../../assets/svgs/CloseIcon";
import TrashIcon from "../../../assets/svgs/TrashIcon";

const Fade = React.forwardRef(function Fade(props, ref) {
  const { in: open, children, onEnter, onExited, ...other } = props;
  const style = useSpring({
    from: { opacity: 0 },
    to: { opacity: open ? 1 : 0 },
    onStart: () => {
      if (open && onEnter) {
        onEnter();
      }
    },
    onRest: () => {
      if (!open && onExited) {
        onExited();
      }
    },
  });

  return (
    <animated.div ref={ref} style={style} {...other}>
      {children}
    </animated.div>
  );
});

// Fade.propTypes = {
//   children: PropTypes.element,
//   in: PropTypes.bool.isRequired,
//   onEnter: PropTypes.func,
//   onExited: PropTypes.func,
// };

const DeleteUserModal = ({
  modalData,
  handleConfirm,
  open,
  handleClose,
  size,
  action,
  loadingClass,
  disableBtn,
}) => {
  return (
    <div>
      <Modal
        aria-labelledby="spring-modal-title"
        aria-describedby="spring-modal-description"
        className="newModalOverlay"
        open={open}
      >
        <div className="newModal">
          <div className="newModalHeader newModalHeader-red">
            <div className="newModalHeaderTitle">
              <h3 className="headerTitle" id="simple-modal-title">
                {modalData?.title}
              </h3>
              <div
                className="headerCloseIcon delete-close-icon"
                onClick={handleClose}
              >
                <CloseIcon />
              </div>
            </div>
          </div>
          <div
            className="newModalBody"
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "16px",
            }}
          >
            <span className="trash-icon">
              <TrashIcon />
            </span>
            {/* <p> {modalData?.description}</p> */}
            <>
              <p
                dangerouslySetInnerHTML={{ __html: modalData?.description }}
              ></p>
            </>

            {action && (
              <div className="flex flex-row-reverse items-center gap-3">
                <Button
                  variant="contained"
                  type="submit"
                  onClick={handleConfirm}
                  className={"delete-button " + loadingClass}
                >
                  Yes, Delete it!
                </Button>
                <Button
                  variant="contained"
                  className="cancel-button"
                  type="submit"
                  disabled={loadingClass}
                  onClick={handleClose}
                  // disabled={disableBtn}
                >
                  Cancel
                </Button>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default DeleteUserModal;
