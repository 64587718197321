import React, { Fragment, useMemo, useState } from "react";
import ChatIconBot from "./ChatIconBot";
import Loading from "../../components/common/ExpandableTable/Loading";
import { format, isToday, isYesterday, subDays } from "date-fns";
import genericAxiosCall from "../../AxiosConfig/genericAxiosCall";
import { serverRoutes } from "../../constants/serverRoutes";
import { useNavigate } from "react-router-dom";
import browserRoute from "../../constants/browserRoutes";
import { useDispatch } from "react-redux";
import { setListingState } from "../../Redux/Reducers/filters/claimManagerFilters";

import DeleteIcon from "../../assets/svgs/DeleteIcon";
import { Popover } from "antd";
import DRMoreDropIcon from "../../assets/svgs/DRMoreDropIcon";
import { Button } from "@mui/material";
import {
  botRoutesChatHistory,
  ErrorToast,
  SuccessToast,
} from "../../components/common/Utils/utils";
import DeleteModal from "../../components/common/DeleteModal";
import { getCopilotHistoryOutputListTimestamp } from "../../Redux/Reducers/UploadPolicy/UploadPolicySlice";
import { useSelector } from "react-redux";

// Group data based on timestamp
const groupByTime = (data) => {
  const today = [];
  const yesterday = [];
  const last7Days = [];
  const last30Days = [];
  const older = {};

  data.forEach((item) => {
    if (!item?.timestamp) return;

    const itemDate = new Date(Number(item?.timestamp));

    if (isToday(itemDate)) {
      today.push(item);
    } else if (isYesterday(itemDate)) {
      yesterday.push(item);
    } else if (itemDate >= subDays(new Date(), 7)) {
      last7Days.push(item);
    } else if (itemDate >= subDays(new Date(), 30)) {
      last30Days.push(item);
    } else {
      const monthName = format(itemDate, "MMMM yyyy");
      if (!older[monthName]) {
        older[monthName] = [];
      }
      older[monthName].push(item);
    }
  });

  return { today, yesterday, last7Days, last30Days, older };
};

// const chatRedirect = (item) => {
//   const params = {
//     conversationId: item?.conversationId,
//   };
//   genericAxiosCall(serverRoutes?.GET_CHAT_DETAILS_BY_ID, "get", "", params)
//     .then((res) => {
//       console.log("hey khizer===", res);
//     })
//     .catch((err) => {
//       console.log("hey khizer err===", err);
//     });
// };

const ChatHistory = ({ data, loading }) => {
  const modalDataObj = {
    title: "Confirm Delete!",
    description: "Are you sure you want to delete?",
  };
  const nav = useNavigate();
  const dispatch = useDispatch();
  const { newChat } = useSelector(
    (state) => state.npReducers.filters.askZoeFilter
  );
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteModalData, setDeleteModalData] = useState(modalDataObj);
  const [deleteLoading, setDeleteLoading] = useState(false);
  const [deleteItem, setDeleteItem] = useState({});
  // Memoize the grouped data to avoid reprocessing on every render
  const chatRedirect = (item) => {
    nav(`${browserRoute?.ASK_ZOE_CHAT}?id=${item?.conversationId}`);
  };
  const handleDelete = () => {
    setDeleteLoading(true);
    const body = {
      conversationId: deleteItem?.conversationId,
    };
    genericAxiosCall(
      serverRoutes?.DELELTE_POLICY_CHAT_HISTORY,
      "post",
      body,
      ""
    )
      .then((res) => {
        if (res?.data?.success) {
          setDeleteModal(false);
          setDeleteItem({});
          SuccessToast(res);
          dispatch(getCopilotHistoryOutputListTimestamp());
        } else {
          UnSuccessfullToast(res);
        }
      })
      .catch((err) => {
        ErrorToast(err);
      })
      .finally(() => {
        setDeleteLoading(false);
      });
  };
  const DisplayChatHistory = ({ item, index }) => {
    return (
      <Fragment key={index}>
        {item?.conversationId && (
          <p className="DR-history-action">
            <span
              onClick={() => {
                chatRedirect(item);
              }}
            >
              {item?.conversationTitle}
            </span>

            <Popover
              content={
                <div className="DR-Drop-More-inner-btn">
                  <Button
                    style={{ minWidth: "20px" }}
                    onClick={() => {
                      setDeleteItem(item);
                      setDeleteModalData({
                        ...deleteModalData,
                        description: `Are you sure you want to delete "${item?.conversationTitle}"`,
                      });
                      setDeleteModal(true);
                    }}
                  >
                    Delete <DeleteIcon />
                  </Button>
                </div>
              }
              trigger="click"
              placement="bottomRight"
              overlayStyle={{ zIndex: "1700" }}
            >
              <Button
                className="DR-Drop-More-inner DR-chat-history ml-1"
                style={{ display: "block" }}
              >
                <DRMoreDropIcon />
              </Button>
            </Popover>
          </p>
        )}
      </Fragment>
    );
  };
  const renderGroup = (label, data) => (
    <>
      {data?.length > 0 && (
        <>
          <p className="history-previous-days-text">{label}</p>
          {data.map((item, index) => (
            <DisplayChatHistory item={item} index={index} key={index} />
          ))}
        </>
      )}
    </>
  );
  const deleteSavedMessages = () => {
    // localStorage.removeItem("saved_Messages_Zoe");
    // localStorage.removeItem("saved_Messages_Zoe_guid");
    // localStorage.removeItem("saved_Messages_Zoe_Title");

    dispatch(
      setListingState({
        listing: "askZoeFilter",
        value: {
          showTableColumnFilter: "",
          newChat: !newChat,
        },
      })
    );
    // nav(browserRoute?.ASK_ZOE);
  };
  const executeNewChat = () => {
    deleteSavedMessages();
  };
  const groupedData = useMemo(
    () =>
      data
        ? groupByTime(data)
        : {
            today: [],
            yesterday: [],
            last7Days: [],
            last30Days: [],
            older: {},
          },
    [data]
  );

  if (loading) {
    return <Loading loaderHeight={40} loaderWidth={40} />;
  }

  return (
    <div className="DR-history-main">
      <button
        className="DR-new-chat-btn-llm"
        onClick={executeNewChat}
        style={{ paddingLeft: "6px", marginBottom: "10px" }}
      >
        {/* <ChatIconBot />  */}
        New Chat{" "}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="icon icon-tabler icon-tabler-edit"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          stroke-width="1"
          stroke="#131722"
          fill="none"
          stroke-linecap="round"
          stroke-linejoin="round"
          className="ml-2"
        >
          <path stroke="none" d="M0 0h24v24H0z" fill="none" />
          <path d="M7 7h-1a2 2 0 0 0 -2 2v9a2 2 0 0 0 2 2h9a2 2 0 0 0 2 -2v-1" />
          <path d="M20.385 6.585a2.1 2.1 0 0 0 -2.97 -2.97l-8.415 8.385v3h3l8.385 -8.415z" />
          <path d="M16 5l3 3" />
        </svg>
      </button>
      <div className="DR-chat-history-scroll">
        {botRoutesChatHistory?.includes(window.location.pathname) && (
          <>
            {/* Render Chat History by Groups */}
            {renderGroup("Today", groupedData.today)}
            {renderGroup("Yesterday", groupedData.yesterday)}
            {renderGroup("Previous 7 Days", groupedData.last7Days)}
            {renderGroup("Previous 30 Days", groupedData.last30Days)}

            {/* Render Older Chats by Month */}
            {Object.keys(groupedData.older).length > 0 && (
              <>
                <p className="history-previous-days-text">Older</p>
                {Object.entries(groupedData.older).map(
                  ([month, chats], index) => (
                    <Fragment key={index}>
                      <p className="history-previous-days-text">{month}</p>
                      {chats.map((item, idx) => (
                        <p
                          key={idx}
                          onClick={() => {
                            chatRedirect(item);
                          }}
                        >
                          {item?.conversationTitle}
                        </p>
                      ))}
                    </Fragment>
                  )
                )}
              </>
            )}
          </>
        )}
      </div>
      <DeleteModal
        modalData={deleteModalData}
        handleConfirm={handleDelete}
        open={deleteModal}
        handleClose={() => {
          setDeleteModal(false);
        }}
        size="sm"
        action={true}
        loadingClass={deleteLoading}
      />
    </div>
  );
};

export default ChatHistory;
